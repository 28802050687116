import { useRecoilValue } from 'recoil';
import Accordion from 'react-bootstrap/Accordion';
import { FaFolderOpen } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
import PDPPanel from '../pages/PDPPanel';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import {
  userProfileState,
} from '../services/state.service';
import {
  UserProfile,
} from '../types/user.types';

function PDPFormAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const userProfile = useRecoilValue<UserProfile>(userProfileState);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaFolderOpen />
        <span className="accordion-header-label">
          {t('PDP.PDP_Accordion_Title')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="row">
          {(userProfile.attestationStatus === '' || userProfile.attestationStatus === 'passed') && (
            <PDPPanel />
          )}
          {(userProfile.attestationStatus === 'pending') && (
            <div className="text-center">{t('PDPPendingAttestation')}</div>
          )}
          {userProfile.attestationStatus === 'failed' && (
            <div className="text-center">{t('PDPFailedAttestation')}</div>
          )}
          {userProfile.attestationStatus === 'expired' && (
            <div className="text-center">{t('PDPExpiredAttestation')}</div>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed(CLC_FUNCTION.callSeeker)(PDPFormAccordionItem);
