import { IData } from './generic.types';

export interface LoginResponse {
  data: {
    access_token: string,
    refresh_token: string,
    user: UserProfile,
  }
}

export const RoleDefaultValue: Role = {
  name: '',
  id: 0,
  abbreviation: '',
  organizationId: 0,
  presbytery: '',
  synod: '',
  functions: [],
  organizationType: '',
};

export interface Role {
  name: string,
  id: number,
  abbreviation: string,
  presbytery: string,
  synod: string,
  organizationId: number,
  functions: string[],
  organizationType: string,
}

export const UserProfileDefaultValue: UserProfile = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  phoneOne: '',
  phoneTwo: '',
  genderDescription: '',
  raceEthnicity: '',
  language: 'en',
  prefix: 0,
  suffix: '',
  gender: '',
  race: '',
  roles: [],
  currentRole: undefined,
  isLoggedIn: false,
  pronouns: '',
  birthYear: 0,
  fullAddress: '',
  profileCompleted: false,
  surveyStarted: '',
  surveyCompleted: '',
  surveyReset: '',
  surveyReopened: false,
  cos_account_approved: false,
  attestationStatus: '',
  needsAnnualReattestation: false,
  ordained: false,
  availableToMatch: false,
  statementOfFaith: '',
};

export interface UserProfile extends IData {
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  addressOne: string,
  addressTwo: string,
  city: string,
  state: string,
  zip: string,
  phoneOne: string,
  phoneTwo: string,
  genderDescription: string,
  raceEthnicity: string,
  language: string,
  prefix: number | string,
  suffix: number | string,
  gender: number | string,
  race: number | string,
  roles: Role[],
  currentRole: number | undefined,
  isLoggedIn: boolean,
  pronouns: string,
  birthYear: number,
  fullAddress: string,
  profileCompleted: boolean,
  surveyStarted: string,
  surveyCompleted: string,
  surveyReset: string,
  surveyReopened: boolean,
  cos_account_approved: boolean,
  attestationStatus: string,
  needsAnnualReattestation: boolean,
  ordained: boolean,
  availableToMatch: boolean,
  statementOfFaith: string,
}

export interface UserProfileResponseData {
  data: {
    options: UserProfile
  }
}

export interface PrefixData {
  id: number,
  description: string,
}

export interface PrefixResponseData {
  data: {
    options: PrefixData[],
  }
}

export interface SuffixData {
  id: number,
  description: string,
}

export interface SuffixResponseData {
  data: {
    options: SuffixData[],
  }
}

export interface GenderData {
  id: number,
  description: string,
}

export interface GenderResponseData {
  data: {
    options: GenderData[],
  }
}

export interface RaceData {
  id: number,
  description: string,
}

export interface RaceResponseData {
  data: {
    options: RaceData[],
  }
}

export interface AttestationListData {
  id: number,
  userId: number,
  name: string,
  ecclesiasticalStatus: string,
  attestationStatus: string,
  attestationDue: boolean,
  attestationNote: string,
  attestedDate: string,
  attestedBy: string,
  pdpId: string,
}

export const AttestationListDefaultData: AttestationListData = {
  id: 0,
  userId: 0,
  name: '',
  ecclesiasticalStatus: '',
  attestationDue: false,
  attestationStatus: '',
  attestationNote: '',
  attestedDate: '',
  attestedBy: '',
  pdpId: '',
};

export interface AttestationListResponseData {
  data: {
    attestations: AttestationListData[],
  }
}
