import { Role, UserProfile } from './types/user.types';
import { MDPData, PresbyteryMatchingOptionData } from './types/mdp.types';
import {
  CLC_FUNCTION,
  MINISTRY_ORG_TYPE,
  PRESBYTERY_MATCHING_OPTIONS,
} from './types/constants';

export const tryParseInt = (input: string): number | undefined => {
  try {
    const result = parseInt(input, 10);
    if (!Number.isNaN(result)) {
      return result;
    }
  } catch {
    // ignore
  }

  return undefined;
};

// Regex expressions for checking if user input is valid
export const validUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/; // eslint-disable-line
export const validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export function BooleanToOnOff(val: boolean | undefined): string {
  if (val === true) return 'on';
  if (val === false) return 'off';
  return '';
}

export function OnOffToBoolean(val: string): boolean | undefined {
  if (val === 'on') return true;
  if (val === 'off') return false;
  return undefined;
}

export function cleanText(val: string): string {
  if (val && val.length > 0) {
    return val.replace(/<\/?[^>]+>/g, '').replace(/\s+/g, ' ').replace(/&nbsp;/g, '');
  }
  return '';
}

export function FormatLocalDate(date: string): string {
  return new Intl.DateTimeFormat('en-US').format(new Date(`${date}T00:00`));
}

export function canRequestRematch(
  currentRole: Role,
  matchingOptions: PresbyteryMatchingOptionData,
  mdp: MDPData,
): boolean {
  if ((currentRole.organizationType === MINISTRY_ORG_TYPE.presbytery
    && currentRole.functions.indexOf(CLC_FUNCTION.manageMatching) > -1)
    && (matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.none
      || (matchingOptions.matchByCommittee && matchingOptions.matchByCommittee.length > 0)
      || matchingOptions.matchByOrdainedPositions)) {
    return true;
  }

  if ((currentRole.organizationType === MINISTRY_ORG_TYPE.congregation
    && currentRole.functions.indexOf(CLC_FUNCTION.manageMatching) > -1)) {
    if (matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.none) return true;
    if (matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.committee
      && !(matchingOptions.matchByCommittee?.includes(mdp?.committee))) return true;
    if (matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.ordained
      && !mdp.ordainedPosition) return true;
  }
  return false;
}

function isValidDate(date: Date): boolean {
  return !Number.isNaN(date.getTime());
}

export function isCallCompleted(callDate: string): boolean {
  const callingDate = new Date(callDate);
  const current = new Date();

  // Check if callingDate is a valid date
  if (!isValidDate(callingDate)) {
    return false;
  }

  return callingDate <= current;
}

export function isCallPending(callDate: string): boolean {
  const callingDate = new Date(callDate);
  const current = new Date();

  // Check if callingDate is a valid date
  if (!isValidDate(callingDate)) {
    return false;
  }

  return callingDate > current;
}

export const sleep = (ms: number) => new Promise<void>((resolve) => {
  setTimeout(resolve, ms);
});

export const getFullName = (userProfile: UserProfile | undefined): string => {
  const namePrefix = userProfile?.prefixDisplay ? `${userProfile.prefixDisplay} ` : '';
  const firstName = userProfile?.firstName ? `${userProfile.firstName} ` : '';
  const middleName = userProfile?.middleName ? `${userProfile.middleName} ` : '';
  const lastName = userProfile?.lastName ? `${userProfile.lastName} ` : '';
  const nameSuffix = userProfile?.suffixDisplay ? ` ${userProfile.suffixDisplay}` : '';

  return `${namePrefix}${firstName}${middleName}${lastName}${nameSuffix}`.trim();
};

export const generateOptions = (
  options: any[],
  keyPrefix: string,
  valueKey: string,
  labelKey: string,
  defaultOption: { value: string; label: string } | null,
) => {
  const optionElements = options.map((option) => (
    <option key={`${keyPrefix}-${option.id}`} value={option[valueKey]}>
      {option[labelKey]}
    </option>
  ));

  if (defaultOption) {
    return [
      <option key={`${keyPrefix}-default`} value={defaultOption.value}>
        {defaultOption.label}
      </option>,
      ...optionElements,
    ];
  }

  return optionElements;
};

export function generateSalaryTooltipContent(content: string): JSX.Element {
  return (
    <a
      target="_blank"
      rel="noreferrer noopener"
      href="https://www.pensions.org/calc/totalSalary"
    >
      {content}
    </a>
  );
}

export const formatCurrency = (value: number): string => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format(value);
