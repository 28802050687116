import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SanitizeHTML from '../services/html.service';
import { cleanText } from '../utils';

import {
  ServiceModalData,
} from '../types/pdp.types';
import CLCInput from './generic/CLCInput';

function ServiceEditModal({
  show,
  service,
  setService,
  saveService,
}: {
  show: boolean,
  service: ServiceModalData,
  setService: Dispatch<SetStateAction<ServiceModalData>>,
  saveService: (_result: boolean, _service: ServiceModalData) => void,
}): JSX.Element {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [validDescription, setValidDescription] = useState<boolean>(true);
  const editorRef = useRef<any>(null);
  const log = (): any => {
    const characterLimit = 1700;

    if (editorRef.current !== null) {
      const cleanedText = cleanText(editorRef.current.getContent());
      if (cleanedText.length > characterLimit) {
        setShowAlert(true);
        setValidDescription(false);
      } else {
        setShowAlert(false);
        setValidDescription(true);
      }
      setService((p: any) => ({
        ...p, description: editorRef.current.getContent(), initial: false,
      }));
    }
  };

  const handleClose = () => {
    if (showAlert) {
      setShowAlert(false);
      setValidDescription(true);
    }
    saveService(false, service);
  };

  useEffect(() => {
    let datesValid = true;
    if (service.start && service.end) {
      const startDate = new Date(service.start);
      const endDate = new Date(service.end);
      if (startDate > endDate) {
        setError(t('End_Date_Must_Be_After_Start_Date'));
        datesValid = false;
      } else {
        setError('');
      }
    } else if (service.start && service.current) {
      setError('');
    }

    let requiredFields = ['position', 'start', 'end', 'organization'];
    if (service.current) {
      requiredFields = ['position', 'start', 'organization'];
    }

    if (requiredFields.every((field) => service[field]) && datesValid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (!validDescription) {
      setIsValid(false);
    }
  }, [service, validDescription]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modalstyle"
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{service.id > 0 ? t('PDP.Edit_Service') : t('PDP.Service_Create_New')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <CLCInput
                  data={service}
                  formLabel={t('Work_Exp_Organization')}
                  stateSetter={setService}
                  inputName="organization"
                  inputType="text"
                  maxLength={100}
                />
              </div>
              <div className="col-xs-12 col-sm-6">
                <CLCInput
                  data={service}
                  formLabel={t('PDP.Position')}
                  stateSetter={setService}
                  inputName="position"
                  inputType="text"
                  maxLength={100}
                />
              </div>
            </div>
            <div className="row">
              <Form.Group className="text-start col-12">
                <Form.Label className="bodyheader">
                  <SanitizeHTML html={t('Service_Description')} />
                </Form.Label>
                <Form>
                  {showAlert && (
                    <div className="col-lg-8 col-12 mx-auto text-center error-text">
                      {t('1000_Character_Limit_Exceeded')}
                    </div>
                  )}
                  <Editor
                    onInit={(evt: any, editor: any): any => { editorRef.current = editor; }}
                    initialValue={service?.description || ''}
                    init={{
                      menubar: false,
                      statusbar: false,
                      branding: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code autoresize',
                      ],
                      toolbar: 'fontselect | formatselect | '
                        + 'alignleft aligncenter alignright alignjustify |'
                        + 'bold italic strikethrough underline | forecolor backcolor link bullist numlist',
                      contextmenu: false,
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      init_instance_callback(editor: any) {
                        editor.on('blur', () => log());
                      },
                    }}
                  />
                </Form>
              </Form.Group>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-3">
                <CLCInput
                  data={service}
                  formLabel={t('PDP.Start_Date')}
                  stateSetter={setService}
                  inputName="start"
                  inputType="date"
                  maxLength={500}
                />
              </div>
              <div className="col-xs-6 col-3">
                {!service.current && (
                  <CLCInput
                    data={service}
                    formLabel={t('PDP.End_Date')}
                    stateSetter={setService}
                    inputName="end"
                    inputType="date"
                    maxLength={500}
                  />
                )}
              </div>
              <div className="col-xs-6 col-sm-6">
                <Form.Label />
                <Form.Check
                  className={`fw-bold${service.end ? '' : ' required'}`}
                  type="checkbox"
                  label={t('Work_Experience_Current')}
                  checked={service.current}
                  onChange={() => setService((l) => ({ ...l, current: !service.current, end: '' }))}
                />
              </div>
            </div>
          </Form.Group>
          <div className="row mb-4">
            <div className="col-12">
              <Form.Text className="text-danger">
                {error}
              </Form.Text>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-3"
          onClick={() => saveService(true, service)}
          disabled={!isValid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceEditModal;
