// MDPResults.tsx
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaShareAlt } from 'react-icons/fa';
import api from '../../services/api.service';
import {
  FavoriteData,
  FavoriteResponseData,
  MDPResultListData,
  PDPMatchData,
} from '../../types/mdp.types';
import {
  ATTESTATION_STATUSES,
} from '../../types/constants';
import { MinistryProfile } from '../../types/ministry.types';
import MDPService, { LanguageData, RequirementsData } from '../../services/mdp.service';
import Pagination from '../Pagination';
import StarButton from '../generic/StarButton';
import { FormatLocalDate } from '../../utils';

interface MDPResultsProps {
  mdpResults: MDPResultListData[];
  mdpDetails: MDPResultListData;
  active: string;
  pdpMatchList: PDPMatchData[];
  statementOfFaithError: boolean;
  requirements: RequirementsData | undefined;
  languageOptions: LanguageData[];
  currency: Intl.NumberFormat;
  userProfile: any;
  ministryProfile: MinistryProfile;
  getMDPDetail: (id: string) => void;
  setActive: (id: string) => void;
  applyToMDP: (id: string, deadline: string) => void;
  totalResults: number;
  totalPages: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const MDPResults: React.FC<MDPResultsProps> = ({
  mdpResults,
  mdpDetails,
  active,
  pdpMatchList,
  statementOfFaithError,
  requirements,
  languageOptions,
  currency,
  userProfile,
  ministryProfile,
  getMDPDetail,
  setActive,
  applyToMDP,
  totalResults,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState<FavoriteData[]>([]);
  const [starRefresh, setStarRefresh] = useState(false);

  const generateMailLink = (mdp: MDPResultListData): string => {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    const subject = 'Find Your Next Calling on the CLC Website!';
    const body = `Take a look at this exciting opportunity available on the Church Leadership Connection (CLC) website:\n\n
      ${baseUrl}/mdp/${mdp.id}/view/`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    return mailtoLink;
  };

  useEffect(() => {
    if (userProfile?.isLoggedIn) {
      api.get('savedopportunity/').then(
        (response: FavoriteResponseData) => {
          setFavorites(response.data);
        },
      );
    }
  }, [starRefresh, userProfile]);

  function saveOpportunity(mdp: MDPResultListData): void {
    MDPService.SubmitOpportunity(mdp).then(
      () => setStarRefresh((r: boolean) => !r),
    );
  }

  function removeOpportunity(mdp: MDPResultListData): void {
    MDPService.DeleteOpportunity(mdp.id).then(
      () => setStarRefresh((r: boolean) => !r),
    );
  }

  return (
    <>
      <div className="my-2">
        <Pagination
          totalResults={totalResults}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className="row">
        <div className="mdp-results-left-panel col-md-3 mb-3">
          {mdpResults?.map((option: MDPResultListData) => (
            <Card
              key={option.id}
              style={{ cursor: 'pointer' }}
              onClick={() => { getMDPDetail(option.id); setActive(option.id); }}
              className={`${option.id === active ? 'active-card' : ''} no-radius`}
            >
              <Card.Body>
                <Card.Title className="bodyheader">
                  {option.positionType ? option.positionType : ''}
                  {option.positionTitle ? ` (${option.positionTitle})` : ''}
                </Card.Title>
                <Card.Text className="mt-3 mb-0 fw-bold">
                  {option.organizationName}
                </Card.Text>
                <Card.Text className="fw-bold">
                  {`${option.city}, ${option.state}`}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
        {mdpResults.length > 0 && mdpDetails.id ? (
          <div className="mdp-results-right-panel col-md-9 mb-3">
            <div className="mdp-details p-3">
              <div className="row">
                <div className="col-9">
                  <h5 className="mb-3 bodyheader">
                    {mdpDetails.positionType ? mdpDetails.positionType : ''}
                    {mdpDetails.positionTitle ? ` (${mdpDetails.positionTitle})` : ''}
                  </h5>
                  <h5 className="mb-2 fw-bold">{mdpDetails.organizationName}</h5>
                  <h5 className="mb-2 fw-bold">{`${mdpDetails.city}, ${mdpDetails.state}`}</h5>
                </div>
                <div className="col-3 text-end">

                  {(userProfile.attestationStatus === ATTESTATION_STATUSES.passed) && (
                    <Button
                      variant="primary"
                      className="mb-2 me-2"
                      onClick={() => applyToMDP(mdpDetails.id, mdpDetails.deadline)}
                      disabled={
                        pdpMatchList.filter((p) => p.mdpId.toString() === mdpDetails.id.toString()
                          && p.selfReferred).length > 0 && !statementOfFaithError
                      }
                    >
                      {pdpMatchList.filter((p) => p.mdpId.toString() === mdpDetails.id.toString()
                        && p.selfReferred).length > 0 && !statementOfFaithError ? (
                          t('Applied')
                        ) : (
                          t('Apply')
                        )}
                    </Button>
                  )}
                  <div className="d-inline-block">
                    {userProfile?.isLoggedIn && (
                      <div className="d-inline-block mb-2">
                        <StarButton
                          handler={() => (favorites.filter((p) => p.id === mdpDetails.id).length > 0 ? (
                            removeOpportunity(mdpDetails)
                          ) : (
                            saveOpportunity(mdpDetails)
                          )
                          )}
                          label=""
                          isSaved={favorites.filter((p) => p.id === mdpDetails.id).length > 0}
                        />
                      </div>
                    )}
                    <a href={generateMailLink(mdpDetails)}>
                      <Button variant="link" className="linkactions mb-2">
                        <FaShareAlt size={36} />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-4 mdp-details-block text-start">
                <div className="col-6 mb-3">
                  <div className="bodyheader">
                    {t('Ministry_Name')}
                  </div>
                  <div>
                    {ministryProfile.ministryName}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="bodyheader">
                    {t('Released_Date')}
                  </div>
                  <div>
                    {mdpDetails.released ? FormatLocalDate(mdpDetails.released) : ''}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="bodyheader">
                    {t('MDP.Experience_Required')}
                  </div>
                  <div>
                    {mdpDetails.experienceLevel}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="bodyheader">
                    {t('PDP.Community')}
                  </div>
                  <div>
                    {mdpDetails.communityType}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="bodyheader">
                    {t('Congregation_Size')}
                  </div>
                  <div>
                    {mdpDetails.congregationSize}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="bodyheader">
                    {t('PDP.Min_Expected_Salary')}
                  </div>
                  <div>
                    {mdpDetails.minimumExpectedSalary ? (
                      `$${currency.format(mdpDetails.minimumExpectedSalary)}`
                    ) : (
                      t('NotSpecified')
                    )}
                  </div>
                </div>
              </div>

              <h5 className="bodyheader">
                {t('Information_About_Position')}
              </h5>
              {/* Position Requirements */}
              <div className="border border-dark rounded-3 text-start mb-3">
                <div className="row">
                  <div className="col-6">
                    <div className="my-4  mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('Position_Types')}
                      </div>
                      {requirements?.positionTypeDescription}
                    </div>
                    <div className="my-4  mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('Employment_Status')}
                      </div>
                      {requirements?.employmentTypeDescription}
                    </div>
                  </div>
                  <div className="col-6">
                    {requirements?.othercert && (
                      <div className="my-4  mx-3">
                        <div className="mb-2 reviewtitle">
                          {t('Other_Training')}
                        </div>
                        {requirements?.othercert}
                      </div>
                    )}
                    <div className="my-4  mx-3">
                      <div className="mb-2 reviewtitle">
                        {t('Language_Requirements')}
                      </div>
                      <div>
                        {requirements?.language.map((option: any) => (
                          <div key={`language-${option}`}>
                            {languageOptions.map((item: any) => (
                              (item.id === option)
                                ? (
                                  <div key={item.description}>
                                    {item.description}
                                  </div>
                                )
                                : null))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                onClick={() => (
                  navigate(`/mdp/${mdpDetails.id}/view/`)
                )}
              >
                {t('View_MDP')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 text-center">
            <div>{t('NoDetails')}</div>
          </div>
        )}
      </div>
      <div className="my-2">
        <Pagination
          totalResults={totalResults}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default MDPResults;
