import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { STATUS_CHOICES } from '../types/constants';
import AcceptButton from './generic/AcceptButton';
import DeclineButton from './generic/DeclineButton';
import {
  PDPMatchData,
  ActionData,
  ActionDefaultData,
} from '../types/mdp.types';
import CancelCallNotificationModal from './CancelCallNotificationModal';
import { isCallPending } from '../utils';
import MDPService from '../services/mdp.service';
import ConfirmModal from './generic/ConfirmModal';

type PDPMatchesProps = {
  pdp: number,
  pdpMatches: PDPMatchData[],
  callback: () => void,
}

function PDPMatches({
  pdp,
  pdpMatches,
  callback,
}: PDPMatchesProps): JSX.Element {
  const { t } = useTranslation();
  const [showCancelCallModal, setShowCancelCallModal] = useState(false);
  const [actionData, setActionData] = useState<ActionData>(ActionDefaultData);
  const [currentMDP, setCurrentMDP] = useState<string>('');
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showDecline, setShowDecline] = useState(false);

  const handleActionChange = (mdpId: number, pdpId: number, accepted: string, statusKey?: string, actionKey?: string): void => {
    setActionData((p : any) => ({
      ...p,
      mdpId,
      pdpId,
      statusKey,
      accepted,
      actionKey,
    }));
  };

  function renderMatchStatus(matchData: PDPMatchData): string {
    const callingDate = new Date(matchData.callingStart);
    const current = new Date();

    if (matchData.status === STATUS_CHOICES.callCompleted) {
      if (callingDate && (callingDate > current)) {
        return t('Calling_Notify_Pending');
      }
      return t('Calling_Notify_Completed');
    }
    if (matchData.status === STATUS_CHOICES.referred && matchData.referredBy) {
      return `${t('Referred_By')} ${matchData.referredBy}`;
    }
    if ((matchData.status === STATUS_CHOICES.requiresAction
        || matchData.status === STATUS_CHOICES.requiresPresbyteryAction)
      && matchData.selfReferred) {
      return t('Self_Referred');
    }

    if (matchData.status === STATUS_CHOICES.notConsidered && matchData.didNotRespond) {
      return t('Not_Considered_DNR');
    }

    return matchData.status;
  }

  function renderActionButtons(option: PDPMatchData): JSX.Element {
    if ((option.statusKey === STATUS_CHOICES.beingConsidered
      || (option.statusKey === STATUS_CHOICES.requiresAction))
      && option.selfReferred === false
    ) {
      return (
        <Button
          className="mb-3 createbutton"
          variant="primary"
          size="sm"
          onClick={() => {
            handleActionChange(option.mdpId, option.pdp, 'False', option.statusKey);
            setShowWithdraw(true);
          }}
        >
          {t('Withdraw_Consideration')}
        </Button>
      );
    }

    if (option.statusKey === STATUS_CHOICES.acceptedinvitation) {
      return (
        <Button
          className="mb-3 createbutton"
          variant="primary"
          size="sm"
          onClick={() => {
            handleActionChange(option.mdpId, option.pdp, 'False', option.statusKey);
            setShowWithdraw(true);
          }}
        >
          {t('Withdraw_Application')}
        </Button>
      );
    }

    if (option.statusKey === STATUS_CHOICES.invitedToApply) {
      return (
        <>
          <AcceptButton
            label=""
            handler={() => {
              handleActionChange(option.mdpId, option.pdp, 'True', option.statusKey);
              setShowAccept(true);
            }}
          />
          <DeclineButton
            label=""
            handler={() => {
              handleActionChange(option.mdpId, option.pdp, 'False', option.statusKey);
              setShowDecline(true);
            }}
          />
        </>
      );
    }

    if (option.statusKey === STATUS_CHOICES.callCompleted
      && option.selfReferred === false
      && isCallPending(option.callingStart)) {
      return (
        <Button
          className="mb-3 createbutton"
          variant="primary"
          size="sm"
          onClick={() => {
            setShowCancelCallModal(true);
            setCurrentMDP(option.mdpId.toString());
          }}
        >
          {t('Cancel_Call_Title')}
        </Button>
      );
    }

    return (
      <td />
    );
  }

  const showAcceptInvite = useCallback((result: boolean): void => {
    if (result && actionData.statusKey) {
      MDPService.SubmitReferralAction(actionData).then(() => {
        callback();
        setShowAccept(false);
      });
    } else {
      setShowAccept(false);
    }
  }, [actionData]);

  const showDeclineInvite = useCallback((result: boolean): void => {
    if (result && actionData.statusKey) {
      MDPService.SubmitReferralAction(actionData).then(() => {
        callback();
        setShowDecline(false);
      });
    } else {
      setShowDecline(false);
    }
  }, [actionData]);

  const showWithdrawInvite = useCallback((result: boolean): void => {
    if (result && actionData.statusKey) {
      MDPService.SubmitReferralAction(actionData).then(() => {
        callback();
        setShowWithdraw(false);
      });
    } else {
      setShowWithdraw(false);
    }
  }, [actionData]);

  const saveCallCanceledReason = useCallback((isSave: boolean, reason: string, mdpId: string): void => {
    if (isSave) {
      MDPService.saveCallCanceledReason(mdpId, reason).then(() => {
        callback();
      });
    }
    setShowCancelCallModal(false);
  }, []);

  return (
    <>
      <CancelCallNotificationModal
        mdpId={currentMDP}
        show={showCancelCallModal}
        description={t('Cancel_Call_Warning_PDP')}
        callback={saveCallCanceledReason}
      />
      <ConfirmModal
        show={showAccept}
        title={t('PDP.Confirm')}
        description={t('Confirm_Accept_Invite')}
        yesLabel={t('Accept_Invite')}
        noLabel={t('PDP.Cancel')}
        callback={showAcceptInvite}
      />
      <ConfirmModal
        show={showDecline}
        title={t('PDP.Confirm')}
        description={t('Confirm_Decline_Invite')}
        yesLabel={t('Decline_Invite')}
        noLabel={t('PDP.Cancel')}
        callback={showDeclineInvite}
      />
      <ConfirmModal
        show={showWithdraw}
        title={t('PDP.Confirm')}
        description={t('Confirm_Withdraw_Invite')}
        yesLabel={t('Withdraw_Consideration')}
        noLabel={t('PDP.Cancel')}
        callback={showWithdrawInvite}
      />
      <tbody>
        {pdpMatches?.map((pdpMatch: any) => (
          pdpMatch.options.length > 0 && pdpMatch.options[0].pdp === pdp && (
            pdpMatch.options?.map((option: any) => (
              <tr key={option.mdpId} className="dividinglines">
                <td>
                  <Link to={`/mdp/${option.mdpId}/view/`} target="_blank">
                    {option.mdpId}
                  </Link>
                </td>
                <td>
                  {option.mdpPositionType ? option.mdpPositionType : ''}
                  {option.mdpPositionTitle ? ` (${option.mdpPositionTitle})` : ''}
                </td>
                <td>{option.organizationName ? option.organizationName : '' }</td>
                <td>{option.city ? option.city : '' }</td>
                <td>{option.state ? option.state : '' }</td>
                <td>{renderMatchStatus(option)}</td>
                <td>
                  <div className="actionicons">
                    {renderActionButtons(option)}
                  </div>
                </td>
              </tr>
            ))
          )
        ))}
      </tbody>
    </>
  );
}

export default PDPMatches;
