import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap';
import {
  FaCheck,
  FaTimes,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';
import { IoIosAlert } from 'react-icons/io';
import PDPService from '../services/pdp.service';
import { PDPMatchData, PDPCalledData } from '../types/mdp.types';
import {
  PDPData,
  PDPDefaultData,
  PDPListResponseData,
  PDPResponseData,
} from '../types/pdp.types';
import EditButton from '../components/generic/EditButton';
import DeleteButton from '../components/generic/DeleteButton';
import ConfirmModal from '../components/generic/ConfirmModal';
import api from '../services/api.service';
import PDPMatches from '../components/PDPMatches';
import {
  PersonalInfoData,
  PersonalInfoDefaultData,
  PersonalInfoResponseData,
} from '../types/profile.types';
import { UserProfile } from '../types/user.types';
import {
  pdpPanelState,
  userProfileState,
} from '../services/state.service';
import { STATUS_CHOICES } from '../types/constants';
import AlertModal from '../components/generic/AlertModal';

function PDPPanel(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [list, setList] = useState<PDPData[]>([]);
  const [currentPDP, setCurrentPDP] = useState<PDPData>(PDPDefaultData);
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [showRows, setShowRows] = useState<boolean[]>([]);
  const [pdpMatches, setPDPMatches] = useState<PDPMatchData[]>([]);
  const [showMisconductDialogue, setShowMisconductDialogue] = useState(false);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoData>(PersonalInfoDefaultData);
  const [isCalledList, setIsCalledList] = useState<PDPCalledData[]>([]);
  const refreshPanel = useRecoilValue(pdpPanelState);

  const handleToggleRow = (index: number): void => {
    setShowRows(showRows.map((show, i) => (i === index ? !show : show)));
  };

  function isPDPCalled(pdplist: PDPData[], pdpMatchData: PDPMatchData[]): PDPCalledData[] {
    const pdpData = pdplist.map((pdpItem) => {
      const match = pdpMatchData.find((matchItem) => matchItem && matchItem.pdp === pdpItem.id);
      const isCalled = match && (match.statusKey === STATUS_CHOICES.callCompleted);
      return {
        pdpId: pdpItem.id,
        isPDPCalled: isCalled,
        startDate: match?.callingStart,
      };
    });
    return pdpData;
  }

  useEffect(() => {
    setIsCalledList([]);
    PDPService.GetPDPList().then(
      (pdplist: PDPListResponseData) => {
        setList(pdplist.data);
        setShowRows(Array(pdplist.data.length).fill(true));
        const fetchData = async (): Promise<void> => {
          const pdpMatchData = await Promise.all(
            pdplist.data.map(async (item: PDPData) => {
              const pdpMatchResponse = await api.get(`/pdpmatch/${item.id}/`);
              return pdpMatchResponse.data;
            }),
          );
          setPDPMatches(pdpMatchData);
          if (pdpMatchData.length > 0) {
            const matchOptionsData = pdpMatchData.map((pdpMatch: any) => pdpMatch.options);
            const matchOptionItems = matchOptionsData.flat();
            if (pdplist.data.length > 0 && matchOptionItems.length > 0) {
              const calledList = isPDPCalled(pdplist.data, matchOptionItems);
              setIsCalledList(calledList);
            }
          }
        };
        fetchData();
      },
    );

    api.get('/profile/personalinfo/').then((response: PersonalInfoResponseData) => {
      setPersonalInfo(response.data[0]);
    });
  }, [refreshList, refreshPanel]);

  function makeNewPDP(item: PDPData): void {
    if (personalInfo.certificationAccepted && (personalInfo.noComplaint
        || (!personalInfo.noComplaint && personalInfo.comments.length > 0))) {
      PDPService.CreatePDP(item).then((response: PDPResponseData) => {
        navigate(`/pdp/${(response.data.id)}/`);
      });
    } else {
      setShowMisconductDialogue(true);
    }
  }

  function pdpStatus(active: boolean | undefined, submitted: boolean): string {
    if (submitted && active) {
      return t('PDP_Status_Active');
    }
    if (submitted && !active) {
      return t('PDP_Status_Inactive');
    }

    return t('PDP_Status_In_Progress');
  }

  function handleToggleChange(e: React.ChangeEvent<HTMLInputElement>, pdpId: number): void {
    PDPService.UpdatePDPStatus(pdpId.toString(), e.target.checked)
      .then(() => {
        setRefreshList((r: boolean) => !r);
      });
  }

  function deactivateCalledPDP(pdpId: number): void {
    PDPService.UpdatePDPStatus(pdpId.toString(), false)
      .then(() => {
        setRefreshList((r: boolean) => !r);
      });
  }

  function isCallCompleted(pdp: PDPData): boolean | undefined {
    return isCalledList.find((item) => item.pdpId === pdp.id)?.isPDPCalled;
  }

  function isToggleDiabled(pdp: PDPData): boolean {
    const calledItem = isCalledList.find((item) => item.pdpId === pdp.id);
    if (calledItem?.startDate) {
      const calledDate = new Date(calledItem?.startDate);
      if (calledItem && calledDate > new Date()) {
        return true;
      }
    }
    if (!pdp.submitted && !pdp.active) {
      return true;
    }
    return false;
  }

  function renderActiveToggle(pdp: PDPData): JSX.Element {
    if (isCallCompleted(pdp) && pdp.active && isToggleDiabled(pdp)) {
      deactivateCalledPDP(pdp.id);
    }
    return (
      <div>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="left"
          overlay={(
            <Tooltip id="{key}-tooltip">
              {t('PDP_Deactivate_Tooltip')}
            </Tooltip>
          )}
        >
          <Form key={`${pdp.id}-${pdp.positionTypeDescription}`}>
            <Form.Check
              type="switch"
              className="custom-switch"
              id={`${pdp.id}-${pdp.positionTypeDescription}`}
              key={`${pdp.id}-${pdp.positionTypeDescription}`}
              checked={pdp.active}
              disabled={isToggleDiabled(pdp)}
              onChange={(e) => handleToggleChange(e, pdp.id)}
            />
          </Form>
        </OverlayTrigger>
      </div>
    );
  }

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentPDP) {
      PDPService.DeletePDP(currentPDP).then(() => {
        setShowDelete(false);
        setRefreshList((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentPDP]);

  const renderActionIcons = (pdp: PDPData) => {
    if (!isToggleDiabled(pdp)) {
      return (
        <div className="actionicons">
          <EditButton
            label=""
            handler={() => navigate(`/pdp/${pdp.id}/`)}
          />
          <DeleteButton
            label=""
            handler={() => {
              setCurrentPDP(pdp);
              setShowDelete(true);
            }}
          />
        </div>
      );
    }

    if (!isCallCompleted(pdp)) {
      return (
        <div className="actionicons">
          <EditButton
            label=""
            handler={() => navigate(`/pdp/${pdp.id}/`)}
          />
          <DeleteButton
            label=""
            handler={() => {
              setCurrentPDP(pdp);
              setShowDelete(true);
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-12">
      {userProfile.needsAnnualReattestation ? (
        <div className="row">
          <div className="alert col-md-6 mb-0">
            <IoIosAlert className="text-danger" />
            <span className="accordion-header-label reattest-label">
              {t('Re-Attestation_Required')}
            </span>
          </div>
          <div className="d-flex col-md-6">
            <Button
              onClick={() => (makeNewPDP(PDPDefaultData))}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              {`+ ${t('PDP.Links_Create_Pdp')}`}
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex">
          <Button
            onClick={() => (makeNewPDP(PDPDefaultData))}
            className="mb-3 ms-auto createbutton"
            variant="primary"
            size="sm"
            active
          >
            {`+ ${t('PDP.Links_Create_Pdp')}`}
          </Button>
        </div>
      )}
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('PDP.Links_Confirmation_Message')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <AlertModal
        show={showMisconductDialogue}
        title={t('Misconduct_Form_Required_Title')}
        description={t('Misconduct_Form_Required_Dialogue')}
        closeLabel={t('OK')}
        callback={() => setShowMisconductDialogue(!showMisconductDialogue)}
      />

      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>
                {t('PDP_ID')}
              </th>
              <th>
                {t('PDP.Position_Type_Title')}
              </th>
              <th>
                {t('Employment_Status')}
              </th>
              <th>
                {t('No_Matching_Within_Presbytery_Title')}
              </th>
              <th>
                {t('PDP.Links_Table_Status')}
              </th>
              <th>
                {t('PDP.Links_Table_Actions')}
              </th>
              <th>
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((pdp: PDPData, index: number) => (
              <React.Fragment key={pdp.id}>
                <tr className="dividinglines">
                  <td>
                    <Link to={`/pdp/${pdp.id}/view/`} target="_blank">
                      {pdp.id}
                    </Link>
                  </td>
                  <td>{pdp.positionTypeDescription}</td>
                  <td>{pdp.employmentType?.description}</td>
                  <td>{pdp.noMatchingWithinPresbytery ? <FaCheck /> : <FaTimes /> }</td>
                  <td>
                    <div className="actionicons">
                      <div className="me-3">
                        {pdpStatus(pdp.active, pdp.submitted)}
                      </div>
                      <div>
                        {renderActiveToggle(pdp)}
                      </div>
                    </div>
                  </td>
                  <td>
                    {renderActionIcons(pdp)}
                  </td>
                  <td>
                    {pdpMatches[index] && (
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="left"
                        overlay={(
                          <Tooltip id="edit-button-tooltip">
                            {t('MDPs_Applied')}
                          </Tooltip>
                        )}
                      >
                        <Button onClick={() => handleToggleRow(index)}>
                          {showRows[index] ? <FaChevronUp /> : <FaChevronDown />}
                        </Button>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
                {pdpMatches[index] && !showRows[index] && (
                  <tr key={`ref-${pdp.id}`}>
                    <td colSpan={7}>
                      <Table striped responsive hover className="mb-3 linktable referraltable">
                        <thead>
                          <tr className="referraltableheader">
                            <th>{t('Match_MDP_ID')}</th>
                            <th>{t('Position_Type_Title')}</th>
                            <th>{t('Organization')}</th>
                            <th>{t('City')}</th>
                            <th>{t('State')}</th>
                            <th>{t('Status')}</th>
                            <th>{t('MDP.Links_Table_Actions')}</th>
                          </tr>
                        </thead>
                        <PDPMatches
                          pdp={pdp.id}
                          pdpMatches={pdpMatches}
                          callback={() => setRefreshList((r: boolean) => !r)}
                        />
                      </Table>
                      <hr className="mdpdivider strongdivider my-3" />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PDPPanel;
