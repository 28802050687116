import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

import MDPService from '../services/mdp.service';
import {
  MDPData,
  MDPResponseData,
  MDPDefaultData,
} from '../types/mdp.types';
import {
  mdpState,
  mdpStepState,
  resubmissionState,
} from '../services/state.service';
import { StepWizardData, DefaultStepWizardData } from '../types/generic.types';
import api from '../services/api.service';
import { MDPSteps } from '../constants/MDPSteps';
import StepWizard from '../components/generic/StepWizard';

function MDPEdit(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(true);
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<StepWizardData>(DefaultStepWizardData);
  const [mdp, setMDP] = useRecoilState<MDPData>(mdpState);
  const [resubmittedMDP, setResubmittedMDP] = useState<MDPData>(MDPDefaultData);
  const [steps, setSteps] = useRecoilState<StepWizardData[]>(mdpStepState);
  const resubmission = useRecoilValue(resubmissionState);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setSteps(MDPSteps);

    // @todo: Calculate activestep on load. For now, it's the first one.
    setActiveStep(MDPSteps[0]);
  }, []);

  const handleStepChange = useCallback(() => {
    MDPService.Update(resubmission ? resubmittedMDP : mdp);
  }, [mdp, mdp.id, resubmittedMDP]);

  useEffect(() => {
    if (params.id) {
      MDPService.GetMDPData(params.id)
        .then((response: MDPResponseData) => {
          setMDP(response.data);
          setResubmittedMDP(response.data);
          setNotFound(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [params.id, setMDP]);

  useEffect(() => {
    if (resubmission) {
      MDPService.GetMDPData(mdp.id)
        .then((response: MDPResponseData) => {
          setResubmittedMDP(response.data);
          setResubmittedMDP((p: any) => ({
            ...p,
            authorized: false,
            clerkApproval: false,
            comApproval: false,
          }));
        });
    }
  }, [resubmission]);

  useEffect(() => {
    if (resubmission) {
      MDPService.Update(resubmittedMDP);
      api.post(`/reapproval/${mdp.organization}/`);
    }
  }, [resubmittedMDP]);

  const formSubmit = useCallback((): void => {
    const isOrgTypeAuth = mdp.organizationType === 'other' || mdp.organizationType === 'presbytery';
    MDPService.Update(
      resubmission
        ? resubmittedMDP : {
          ...mdp,
          submitted: true,
          authorized: isOrgTypeAuth || mdp.authorized,
        },
    )
      .then(() => {
        navigate('/');
      });
  }, [mdp, navigate]);

  if (loading) {
    return (
      <Spinner
        animation="border"
        size="sm"
      />
    );
  }

  return notFound ? (
    <div className="container-fluid">
      <h1 className="text-center mt-3">{t('MDPNotFound')}</h1>
    </div>
  ) : (
    <StepWizard
      steps={steps}
      handleStepChange={handleStepChange}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      formSubmit={formSubmit}
      id={parseInt(mdp.id, 10)}
    />
  );
}

export default MDPEdit;
